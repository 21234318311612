import api from "./api";

export default {
    api: 'datastores',
    /**
     * Get all datastores
     * @returns {Promise}
     */
    list() {
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get a datastore by id
     * @param {String|Number} id Id from datastore
     * @returns {Promise}
     */
    get(id) {
        return new Promise((resolve, reject) => {
            api().get(this.api + '/' + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new Datastore entry
     * @param {object} data Object with all attributes
     * @returns {Promise}
     */
    add(data) {
        return new Promise((resolve, reject) => {
            api().post(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Update a datastore
     * @param {object} data must contain the id of the processing datastore
     * @returns {Promise}
     */
    edit(data) {
        return new Promise((resolve, reject) => {
            api().put(this.api + '/' + data.id, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete datastore
     * @param {array} data array of datastore ids to delete
     * @returns {Promise}
     */
    delete(data) {
        return new Promise((resolve, reject) => {
            api().delete(this.api, {data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Returns a color variant mapped by percentage. Intended for mass storage usage
     * @param {Number} percent Percentage of mass storage usage
     * @returns {string}
     */
    getCapacityState(percent){
        if(percent > 80){
            return 'danger';
        } else if(percent > 60){
            return 'warning';
        }
        return 'primary';
    },
};
