
export default {
    api: 'files',
    /**
     * Convert bytes to readable size in KiB, MiB, GiB, TiB
     * @param {number} bytes
     * @returns {string}
     */
    bytesToSize(bytes) {
        let sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB'];
        if (bytes === 0) return '0 Byte';
        let i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (Math.round(((bytes / Math.pow(1024, i)) + Number.EPSILON) * 100) / 100) + ' ' + sizes[i];
    }
};
